const app = () => {
  return (
    <div className="app">
      <span>
        <h1>Din Blomsterflicka</h1>
        <h2>Florist A-K Wilmi</h2>
      </span>
      <p>
        <a href="mailto:florist@dinblomsterflicka.se"><i class="fa-solid fa-envelope"></i> florist@dinblomsterflicka.se </a> <br />
        <a href="https://www.instagram.com/dinblomsterflicka/" rel="noreferrer" target="_blank"><i class="fa-brands fa-instagram"></i> dinblomsterflicka</a>
      </p>
      <div className="quote">”Flowers are the music of the ground from earth's lips spoken without sound.” ~ Edwin Curran</div>
    </div>
  );
}

export default app;
